import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homebody from './Components/Homebody/Homebody';
import Login from './Components/Login/Login';
import Signup from './Components/Login/Signup';
import Resetpassword from './Components/Login/Resetpassword';
import Zoom from './Components/Zoom/Zoom';
import Admin from './Components/Zoom/Admin';
import ConfirmSignup from './Components/Login/ConfirmSignup';
import ConfirmNewPassword from './Components/Login/ConfirmNewPassword';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import InitialUserInfo from './Components/Login/InitialUserInfo';
import InactivityProvider from './Components/InactivityProvider/InactivityProvider';
import Meeting from './Components/Meeting/Meeting';
// import Profile from './Components/Profile/Profile';

Amplify.configure(awsExports);

function App() {
  return (
    <Router>
      <InactivityProvider>
        <Routes>
          <Route path="" element={<Homebody />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route path="/confirm-new-password" element={<ConfirmNewPassword />}/>
          {/* <Route path="/zoom" element={<Zoom />} />
          <Route path="/admin" element={<Admin />} /> */}
          <Route path="/meeting" element={<Meeting />} />
          <Route path="/confirm-signup" element={<ConfirmSignup />} />
          <Route path="/initial-user-info" element={<InitialUserInfo />} />
          {/* <Route path="/profile" element={<Profile />}/> */}
        </Routes>
      </InactivityProvider>
    </Router>
  );
}

export default App;
