import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { confirmResetPassword } from 'aws-amplify/auth';
import { ClipLoader } from "react-spinners";
import "./Login.css";
import awsconfig from '../../aws-exports';
import useSubmitButton from '../../hooks/useSubmitButton';
import { getErrorMessage } from '../../utils/errorMessages';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

Amplify.configure(awsconfig);

function ConfirmNewPassword() {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const validatePassword = (password) => {
    if (password.length < 6) {
      throw new Error(getErrorMessage('INVALID_PASSWORD'));
    }
    return true;
  };

  const submitAction = async () => {
    if (!email) {
      throw new Error(getErrorMessage('INVALID_EMAIL'));
    }

    if (newPassword !== confirmPassword) {
      throw new Error(getErrorMessage('PASSWORD_MISMATCH'));
    }

    if (!validatePassword(newPassword)) {
      throw new Error(getErrorMessage('INVALID_PASSWORD'));
    }

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword,
      });
      console.log('Password reset successful');
      navigate('/login');
    } catch (error) {
      console.error('Error:', error);
      throw new Error(getErrorMessage(error));
    }
  };

  const { isLoading, error, handleSubmit } = useSubmitButton(submitAction);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`login-page ${isLoading ? 'loading' : ''}`}>
      <div className="login_form">
        <form onSubmit={handleSubmit}>
          <h2>新しいパスワードの設定</h2>
          <p className="resetpass-explain">
            メールで受け取った確認コードと新しいパスワードを入力してください。
          </p>
          {error && <p className="error-message">{error}</p>}
          <div className="input_box" style={{ display: 'none' }}>
            <label htmlFor="email">メールアドレス</label>
            <input
              type="email"
              id="email"
              placeholder={email}
              required
              value={email}
              autoComplete="username"
            />
          </div>
          <div className="input_box">
            <label htmlFor="code">確認コード</label>
            <input
              type="text"
              id="code"
              placeholder="確認コードを入力してください"
              required
              value={code}
              onChange={(e) => setCode(e.target.value)}
              autoComplete="one-time-code"
            />
          </div>
          <div className="input_box">
            <label htmlFor="newPassword">新しいパスワード</label>
            <div className="password-input-eye-container">
              <div className="passwordinput">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="newPassword"
                  placeholder="新しいパスワードを入力してください"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  autoComplete="current-password"
                />
              </div>

              <div className="showpassword">
                {showPassword ? (
                  <FaEyeSlash onClick={togglePasswordVisibility} />
                ) : (
                  <FaEye onClick={togglePasswordVisibility} />
                )}
              </div>
            </div>
          </div>
          <div className="input_box">
            <label htmlFor="confirmPassword">新しいパスワード（再入力）</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="新しいパスワードを再入力してください"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="current-password"
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'パスワード変更中...' : 'パスワードを変更する'}
          </button>
        </form>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <ClipLoader className="spinner" loading={isLoading} />
        </div>
      )}
    </div>
  );
}

export default ConfirmNewPassword;
