import { useEffect } from "react";
import "./Meeting.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import { Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners"; // Import ClipLoader

// Configure Amplify with AWS settings
Amplify.configure(awsconfig);
const apiDomain = awsconfig.aws_cloud_logic_custom[0].endpoint;
const leave_Url = awsconfig.oauth.redirectSignIn;

function Meeting() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [loading, setLoading] = useState(false); // Add loading state
  const [canStartMeeting, setCanStartMeeting] = useState(false); // New state to control meeting start

  const [attendees, setAttendees] = useState([]);
  const [pairs, setPairs] = useState([]);

  // State to hold the update message
  const [updateMessage, setUpdateMessage] = useState("");

  // Add state variables for session name and start time
  const [sessionName, setSessionName] = useState("----");
  const [sessionStartTime, setSessionStartTime] = useState("----");

  // Save userInfo to session storage when it changes
  useEffect(() => {
    if (userInfo) {
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      // console.log("sessionStorage successful");
    }
  }, [userInfo]);

  // Function to display a message and remove it after 3 seconds
  function displayTemporaryMessage(message) {
    setUpdateMessage(message);
    setTimeout(() => {
      setUpdateMessage("");
    }, 3000);
  }

  // Retrieve userInfo from session storage on component mount
  // Retrieve userInfo from session storage on component mount
  const storedUserInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
  const nation =
    userInfo?.user?.nation || storedUserInfo?.user?.nation || "defaultNation";
  const userNameJP = userInfo?.user
    ? `${userInfo.user.family_name} (${userInfo.user.family_name_kana})`
    : storedUserInfo?.user
    ? `${storedUserInfo.user.family_name} (${storedUserInfo.user.family_name_kana})`
    : "defaultUserNameJP";
  const userNameVN = userInfo?.user
    ? `${userInfo.user.family_name} ${userInfo.user.first_name} (${userInfo.user.first_name_kana})`
    : storedUserInfo?.user
    ? `${storedUserInfo.user.family_name} ${storedUserInfo.user.first_name} (${storedUserInfo.user.first_name_kana})`
    : "defaultUserNameVN";
  const userEmail =
    userInfo?.user?.email || storedUserInfo?.user?.email || "defautl@gmail.com";

  // Redirect to root if default values are used
  useEffect(() => {
    // Check for default values and update state
    if (
      userNameJP === "defaultUserNameJP" ||
      userNameVN === "defaultUserNameVN" ||
      userEmail === "default@gmail.com"
    ) {
      setCanStartMeeting(false);
      navigate("/")
    } else {
      setCanStartMeeting(true);
    }
  }, []);

  let userName;
  if (nation === "Japan") {
    userName = userNameJP;
  } else {
    userName = userNameVN;
  }

  const authEndpoint = `${apiDomain}/auth-zoom`;
  const sdkKey = "y7quhxLnRrSAjCsADDlLXg";
  const meetingNumber = "84355504154";
  // const meetingNumber = "87887970670"
  const passWord = "";
  const role = userInfo?.user?.user_roles[0]?.role_name || 0;
  const registrantToken = "";
  const zakToken = "";
  const leaveUrl = `${leave_Url}`;

  // // Function to get the signature for Zoom Meeting SDK
  const getSignature = async () => {
    setLoading(true);
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;
      // console.log("session", session);
      const req = await fetch(authEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          meetingNumber: meetingNumber,
          role: role,
        }),
      });
      const res = await req.json();
      const signature = res.signature;
      startMeeting(signature);
    } catch (e) {
      console.error("Error fetching signature:", e);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const mapUser = async (sessionUser) => {
    // Fetch authentication session and token
    const session = await fetchAuthSession();
    const token = session.tokens.idToken; // Ensure this is the correct path to the JWT token

    // Extract userId and persistentID from sessionUser
    const { persistentID } = sessionUser?.result?.currentUser;

    const zoomUserInfo = {
      zoom_id: persistentID,
    };

    // Send user attributes to the server
    const response = await axios.post(
      `${apiDomain}/usermap`,
      {
        ...zoomUserInfo,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure the format is correct
        },
      }
    );
  };

  // Function to start the Zoom meeting
  const startMeeting = async (signature) => {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/3.10.0/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // Load the Japanese language pack
    ZoomMtg.i18n.load("jp-JP");

    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      enableHD: true,
      enableFullHD: true,
      patchJsMedia: true,
      leaveOnPageUnload: false,
      success: (success) => {
        ZoomMtg.join({
          sdkKey: sdkKey,
          signature: signature, // role in SDK signature needs to be 0
          meetingNumber: meetingNumber,
          userEmail: userEmail,
          passWord: passWord,
          userName: userName,
          success: (joinSuccess) => {
            console.log("The meeting is create successful: ", joinSuccess);
            try {
              ZoomMtg.getCurrentUser({
                success: (res) => {
                  // console.log("Current User Info:", res);
                  // Mapping zoom user id and user id of the system
                  if(res?.result?.currentUser?.persistentID){
                    mapUser(res);
                  }

                },
                error: (err) => {
                  console.error("Error getting current user:", err);
                },
              });
            } catch (error) {
              console.error("Unexpected error in getCurrentUser:", error);
            };
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (canStartMeeting) {
      try {
        getSignature();
      } catch (error) {
        navigate("/");
      }
    }
  }, [canStartMeeting]);

  useEffect(() => {
    const handlePopState = (event) => {
      const confirmationMessage = "ミーティングに退出しますか？";
      if (window.confirm(confirmationMessage)) {
        // Use ZoomMtg.leaveMeeting to leave the meeting
        // Use ZoomMtg.leaveMeeting to leave the meeting
        try {
          ZoomMtg.leaveMeeting({
            confirm: false, // Set to false to leave directly without additional confirmation
            success: () => {
              console.log("Successfully left the meeting.");
            },
            error: (err) => {
              console.error("Error leaving the meeting:", err);
              navigate("/"); // Navigate to root if there's an error
            },
          });
        } catch (err) {
          console.error("Unexpected error leaving the meeting:", err);
          navigate("/"); // Navigate to root if there's an unexpected error
        }
      } else {
        // Prevent navigation
        window.location.reload();
        window.history.pushState(null, null, window.location.pathname);
      }
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleAdminButtonClick = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Function to fetch attendees list
  const fetchAttendeesList = () => {
    ZoomMtg.getAttendeeslist({
      success: (res) => {
        console.log("Attendees List:", res);
        setAttendees(res.result.attendeesList);
      },
      error: (err) => {
        console.error("Error getting attendees list:", err);
        alert("Failed to get attendees list.");
      },
    });
  };

  const fetchPairList = () => {
    try {
      ZoomMtg.getBreakoutRooms({
        success: (res) => {
          // console.log("res: ", res);
          const breakoutRooms = res.result.rooms;
          const roomParticipants = breakoutRooms
            .filter(room => room.participants.length > 0) // Only include rooms with participants
            .map((room) => {
              return room.participants.reduce((acc, participant, index) => {
                const otherParticipant = room.participants[(index + 1) % room.participants.length];
                acc[participant.displayName] = `${otherParticipant.displayName}, ${room.name}`;
                return acc;
              }, {});
            });

          // Merge all objects in roomParticipants into a single object
          const mergedRoomParticipants = roomParticipants.reduce((acc, curr) => {
            return { ...acc, ...curr };
          }, {});

          // Sort the merged object by keys
          const sortedMergedRoomParticipants = Object.keys(mergedRoomParticipants)
            .sort()
            .reduce((acc, key) => {
              acc[key] = mergedRoomParticipants[key];
              return acc;
            }, {});
          // Convert the merged object back to an array with a single element
          const finalResult = [sortedMergedRoomParticipants];

          // Update your component state with the roomParticipants data
          setPairs(finalResult);

          // Export the roomParticipants data
        },
        error: (err) => {
          console.error("Error fetching breakout room data:", err);
        },
      });
    } catch (error) {
      console.error("Error executing getBreakoutRooms:", error);
    }
  };

  async function fetchBreakRoom() {
    try {
      return new Promise((resolve, reject) => {
        ZoomMtg.getBreakoutRooms({
          success: (res) => {
            const rooms = res.result.rooms;
            const unassigned = res.result.unassigned;
            console.log("getBreakoutRooms", res.result);
            resolve({ rooms, unassigned });
          },
          error: (err) => {
            console.error("Error fetching breakout rooms:", err);
            reject([]); 
          },
        });
      });
    } catch (error) {
      console.error("Unexpected error executing getBreakoutRooms:", error);
      return []; 
    }
  }

  // get userlist and roomlist is empty, export user_id and room_id for each user
  // matchingAttendees(users)
  // input: user = [user_id1, user_id2, user_id3, ...] for breakout user and unassign user
  // function matchingAttendees(breakoutUsers, unassignAttendees, emptyRoomList) {
  //   console.log("breakoutUsers:", breakoutUsers);
  //   console.log("unassignAttendees:", unassignAttendees);
  //   const result = [];
  //   let filterUnAssignAttendees = [];

  //   // Now `filterUnAssignAttendees` contains attendees not in breakout room
  //   if (breakoutUsers.length > 0 || unassignAttendees.length > 0) {
  //     filterUnAssignAttendees = unassignAttendees.filter(
  //       (item) => !breakoutUsers.includes(item)
  //     );
  //     // Now `filterUnAssignAttendees` contains attendees not in breakout rooms.
  //     console.log(filterUnAssignAttendees);
  //   } else {
  //     // Log an error or handle the case where arrays are not properly initialized
  //     console.log(
  //       "Either breakoutUsers or unassignAttendees is not an array or is empty."
  //     );
  //   }
  //   // // Fetch break rooms
  //   // const breakRoomList = fetchBreakRoom();
  //   // // Filter only empty rooms to avoid altering the original breakRoomList unnecessarily
  //   // const emptyRoomList = breakRoomList.filter(
  //   //   (room) => room.attendeeList.length === 0
  //   // );

  //   // Early exit if no empty rooms are available
  //   if (emptyRoomList.length === 0) {
  //     console.error("No empty rooms available");
  //     return result;
  //   }

  //   // Combine both user lists
  //   const users = [...breakoutUsers, ...filterUnAssignAttendees];

  //   // Minimize the loop condition checks to handle all users that can be paired
  //   for (
  //     let i = 0, roomIndex = 0;
  //     i < users.length && roomIndex < emptyRoomList.length;
  //     i += 2, roomIndex++
  //   ) {
  //     // Take two users at a time
  //     let roomUsers = users.slice(i, i + 2);

  //     // Fetch the current room based on the room index
  //     let currentRoom = emptyRoomList[roomIndex];

  //     // Assign each user to the current room and determine their original group
  //     roomUsers.forEach((user) => {
  //       result.push({
  //         user_id: user,
  //         room_id: currentRoom.boId,
  //         // Fix the condition to check presence properly and assign status
  //         status: breakoutUsers.includes(user) ? "breakout" : "unassign",
  //       });
  //     });
  //   }

  //   // Check if not all users could be paired or assigned due to room shortage
  //   if (Math.floor(users.length / 2) >= emptyRoomList.length) {
  //     console.error("Not enough empty rooms for all users");
  //   }

  //   console.log("Matching and pairing complete for each user pair", result);
  //   return result;
  // }

  function matchingAttendees(breakoutUsers, unassignAttendees, emptyRoomList, responseData) {
    console.log("breakoutUsers:", breakoutUsers);
    console.log("unassignAttendees:", unassignAttendees);
    console.log("responseData:", responseData); // Log the responseData for debugging
    const result = [];
    let filterUnAssignAttendees = [];
  
    // Now `filterUnAssignAttendees` contains attendees not in breakout room
    if (breakoutUsers.length > 0 || unassignAttendees.length > 0) {
      filterUnAssignAttendees = unassignAttendees.filter(
        (item) => !breakoutUsers.includes(item)
      );
      // Now `filterUnAssignAttendees` contains attendees not in breakout rooms.
      console.log(filterUnAssignAttendees);
    } else {
      // Log an error or handle the case where arrays are not properly initialized
      console.log(
        "Either breakoutUsers or unassignAttendees is not an array or is empty."
      );
    }
  
    // Early exit if no empty rooms are available
    if (emptyRoomList.length === 0) {
      console.error("No empty rooms available");
      return result;
    }
  
    // Use responseData to pair users
    responseData.forEach(pair => {
      const [user1, user2] = pair;
      const currentRoom = emptyRoomList.shift(); // Get the next available empty room
  
      if (currentRoom) {
        [user1, user2].forEach(user => {
          result.push({
            user_id: user,
            room_id: currentRoom.boId,
            status: breakoutUsers.includes(user) ? "breakout" : "unassign",
          });
        });
      } else {
        console.error("Not enough empty rooms for all user pairs");
      }
    });
  
    console.log("Matching and pairing complete for each user pair", result);
    return result;
  }

  async function assignAttendeestoBreakOutRoom(attendee) {
    try {
      console.log(
        `Start to assign user ${attendee.user_id} to room ${attendee.room_id}`
      );

      ZoomMtg.assignUserToBreakoutRoom({
        userId: attendee.user_id,
        targetRoomId: attendee.room_id,
      });
    } catch (error) {
      console.error(`Error assigning user ${attendee.user_id}:`, error);
    }
  }

  async function moveUsertoBreakOutRoom(attendee) {
    try {
      console.log(
        `Attempting to move user ${attendee.user_id} to room ${attendee.room_id}`
      );
      ZoomMtg.moveUserToBreakoutRoom({
        userId: attendee.user_id,
        targetRoomId: attendee.room_id,
      });
    } catch (error) {
      console.error(`Error moving user ${attendee.user_id}:`, error);
    }
  }

  async function changeRoomForUserInBreakoutRoom() {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/3.10.0/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;
      const { rooms, unassigned } = await fetchBreakRoom();

      // Ensure both lists are arrays
      const breakroomUserId = rooms.flatMap((room) =>
        room.participants.map((participant) => participant.participantId)
      );

      // Assuming 'data' is the object containing the 'rooms' and 'unassigned' properties
      const unassignUserId = unassigned.map(
        (participant) => participant.participantId
      );

      const emptyRoomList = rooms
        .filter((room) => room.participants.length === 0)
        .map((room) => room); // Return a list of empty room names

      // Get the room have participants only
      const breakroomUser = rooms.filter(
        (room) => room.participants.length > 0
      );

      console.log("breakroomUserId", breakroomUser);

      const matchEndpoint = `${apiDomain}/user-pairing`;

      const response = await fetch(matchEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          breakroomUser: breakroomUser,
          unassigned: unassigned,
          sessionID: sessionName,
        }),
      });

      // Ensure the response is parsed as JSON
      const responseData = await response.json();
      console.log("Response Data:", responseData);

      // Access the 'pairs' array from the response
      const pairs = responseData.pairs

      // Check if responseData is an array
      if (!Array.isArray(pairs)) {
        throw new TypeError("Expected responseData to be an array");
      }

      const userPairResult = matchingAttendees(
        breakroomUserId,
        unassignUserId,
        emptyRoomList,
        pairs
      );

      console.log("User pair result: ", userPairResult);

      const breakoutList = userPairResult.filter(
        (element) => element.status === "breakout"
      );
      const unassignList = userPairResult.filter(
        (element) => element.status === "unassign"
      );

      console.log("breakoutList: ", breakoutList);
      console.log("unassignList: ", unassignList);

      if (unassignList.length > 0) {
        for (let user of unassignList) {
          try {
            await Promise.race([
              assignAttendeestoBreakOutRoom(user),
              new Promise((_, reject) => setTimeout(() => reject(), 250)),
            ]);
          } catch (error) {
            console.error(
              `Failed to move user ${user.user_id} within 500ms:`,
              error
            );
          }
          // await assignAttendeestoBreakOutRoom(user);
          await new Promise((resolve) => setTimeout(resolve, 250));
        }
        console.log("Complete move unassign user to breakroom!");
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      if (breakoutList.length > 0) {
        for (const user of breakoutList) {
          try {
            await Promise.race([
              moveUsertoBreakOutRoom(user),
              new Promise((_, reject) => setTimeout(() => reject(), 250)),
            ]);
          } catch (error) {
            console.error(
              `Failed to move user ${user.user_id} within 500ms:`,
              error
            );
          }
          await new Promise((resolve) => setTimeout(resolve, 250));
        }
        console.log("Complete move user to new breakroom!");
      }
    } catch (error) {
      console.error("Error in changeRoomForUserInBreakoutRoom:", error);
    }
  }

  async function userMatchingHistory() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;
      const { rooms } = await fetchBreakRoom();
      // Get the room have participants only
      const breakroomUser = rooms.filter(
        (room) => room.participants.length > 0
      );

      console.log("breakroomUserId", breakroomUser);

      const matchEndpoint = `${apiDomain}/match-history`;

      const response = await fetch(matchEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          breakroomUser: breakroomUser,
          sessionID: sessionName,
        }),
      });

      if (response.ok) {
        displayTemporaryMessage("履歴が正常に更新されました。"); // Success message
      } else {
        displayTemporaryMessage("履歴の更新に失敗しました。"); // Failure message
      }
    } catch (error) {
      console.error("Error in userMatchingHistory:", error);
      displayTemporaryMessage("履歴の更新中にエラーが発生しました。"); // Error message
    }
  }

  async function fetchSessionInfo() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;

      const matchEndpoint = `${apiDomain}/session-info`;

      const response = await fetch(matchEndpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("respone: ", response);

      if (response.ok) {
        const data = await response.json(); // Parse the JSON body
        console.log("data: ", data);
        if (data != null) {
          displayTemporaryMessage("セッション情報が正常に取得できました"); // Success message
        } else {
          displayTemporaryMessage(
            "セッションが存在しません。新しセッションを作成してください"
          ); // Success message
        }

        setSessionName(data?.session_name || "----"); // Access the parsed data
        setSessionStartTime(data?.session_start_time || "----"); // Access the parsed data
      } else {
        displayTemporaryMessage("エラーが発生しました"); // Failure message
      }
    } catch (error) {
      console.error("Error in userMatchingHistory:", error);
      displayTemporaryMessage("エラーが発生しました"); // Error message
    }
  }

  async function endSession() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;

      const matchEndpoint = `${apiDomain}/session-info`;

      const response = await fetch(matchEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          action: "endSession",
          sessionId: sessionName,
        }),
      });

      console.log("respone: ", response);

      if (response.ok) {
        const data = await response.json(); // Parse the JSON body
        console.log("data: ", data);
        if (data == null) {
          displayTemporaryMessage("セッションが正常に終了できました"); // Success message
        }

        setSessionName(data?.session_name || "----"); // Access the parsed data
        setSessionStartTime(data?.session_start_time || "----"); // Access the parsed data
      } else {
        displayTemporaryMessage("エラーが発生しました"); // Failure message
      }
    } catch (error) {
      console.error("Error in endSession:", error);
      displayTemporaryMessage("エラーが発生しました"); // Error message
    }
  }

  async function createSession() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;

      const matchEndpoint = `${apiDomain}/session-info`;

      const response = await fetch(matchEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          action: "createSession",
        }),
      });

      console.log("respone: ", response);

      if (response.ok) {
        const data = await response.json(); // Parse the JSON body
        console.log("data: ", data);
        if (data != null) {
          displayTemporaryMessage("セッションが正常に作成できました"); // Success message
        }

        setSessionName(data?.session_name || "----"); // Access the parsed data
        setSessionStartTime(data?.session_start_time || "----"); // Access the parsed data
      } else {
        displayTemporaryMessage("エラーが発生しました"); // Failure message
      }
    } catch (error) {
      console.error("Error in createSession:", error);
      displayTemporaryMessage("エラーが発生しました"); // Error message
    }
  }

  return (
    <>
      {loading && (
        <div className="loading-container">
          <ClipLoader />
        </div>
      )}
      <div
        id="zmmtg-root"
        style={{ display: "none", position: "relative" }}
      ></div>
      {role && (
        <button id="overlay-button" onClick={handleAdminButtonClick}>
          Admin
        </button>
      )}
      {showModal && (
        <div id="modal">
          <div id="session-info">
            <p>
              <span>セッション</span>
            </p>
            <p>
              現状セッション: <span>{sessionName}</span>
            </p>
            <p>
              開始時間: <span>{sessionStartTime}</span>
            </p>
            <button onClick={fetchSessionInfo}>更新</button>
            {/* <button id="close-session">セッション終了</button> */}
            {sessionName !== "----" ? (
              <button
                id="close-session"
                onClick={() => {
                  if (window.confirm("このセッションを終了しますか？")) {
                    endSession(); // Call the function if confirmed
                  }
                }}
              >
                セッション終了
              </button>
            ) : (
              <button
                id="create-session"
                onClick={() => {
                  if (window.confirm("新しいセッションを作成しますか？")) {
                    createSession(); // Call the function if confirmed
                  }
                }}
              >
                新しセッション作成
              </button>
            )}

            <button
              onClick={() => {
                if (
                  window.confirm(
                    "このセッションのユーザーのマッチ履歴を更新しますか？"
                  )
                ) {
                  userMatchingHistory(); // Call the function if confirmed
                }
              }}
            >
              ペア履歴更新
            </button>
          </div>

          <div className="message-container">
            <p>{updateMessage && <div>{updateMessage}</div>}</p>
          </div>

          <div id="attendees-list">
            <button id="attendees-button" onClick={fetchAttendeesList}>
              参加者一覧
            </button>
            <ol>
              {attendees.map((attendee) => (
                <li key={attendee.userId}>
                  {attendee.userId}: {attendee.userName}
                </li>
              ))}
            </ol>
          </div>

          <div id="attendees-pair-list">
            <button id="attendees-button" onClick={fetchPairList}>
              ペア一覧
            </button>
              {pairs.map((pair, index) => (
                  <ol>
                    {Object.entries(pair).map(([participant, details]) => (
                      <li key={participant}>
                        {participant}: {details}
                      </li>
                    ))}
                  </ol>
              ))}
          </div>

          <div id="attendees-pair-list">
            <button
              id="attendees-button"
              onClick={changeRoomForUserInBreakoutRoom}
            >
              切り替え
            </button>
            <button
              id="history-update-button" // Add a new button for userMatchingHistory
              onClick={userMatchingHistory}
            >
              履歴更新
            </button>
            {/* <ol>
              {pairs.map((pair, index) => (
                <li key={index}>
                  {pair.room}, {pair.participants}
                </li>
              ))}
            </ol> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Meeting;
